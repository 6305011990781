<!-- eslint-disable -->
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import useFonts from '../composables/useFonts';
const { setFont } = useFonts();

onMounted(() => {
    setFont()
})

useUtms();
</script>

<template lang="pug">
.app-wrapper 
    ClientOnly
        GlobalAppPopup
    GlobalAppModalError
    GlobalAppSpinner
    GlobalAppHeader
    GlobalAppActionSticky
    GlobalAppGoTop
    slot
    GlobalAppFooter 
    ClientOnly(fallback-tag="span", fallback="Loading on server...")
        GlobalModalCustom
    
</template>



<style lang="scss"></style>